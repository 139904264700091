@import '../../../../scss/theme-bootstrap';

$main-menu-bottom-padding: 200px;
$gnav-animation: slide-in-#{$ldirection} 0.3s ease-in;
$gnav-mb-full-height: calc(#{$gnav-promo-mb-height} + #{$gnav-courtesy-height} + 1px);
$gnav-pc-sticky-height: calc(#{$gnav-courtesy-height} + #{$gnav-pc-nav-height} + 1px);
$gnav-pc-full-height: calc(#{$gnav-promo-pc-height} + #{$gnav-pc-sticky-height});
$close-icon-size: 17px;

.gnav-block {
  z-index: 1000;
  width: 100%;
  height: $gnav-mb-full-height;
  min-height: $gnav-mb-full-height;
  background-color: $color-white;
  position: relative;
  @media #{$cr19-large-up} {
    z-index: 9;
    height: $gnav-pc-full-height;
    min-height: $gnav-pc-full-height;
  }
  .active-gnav &,
  .search-overlay-displayed & {
    height: $gnav-courtesy-height;
    min-height: $gnav-courtesy-height;
  }
  &__nav-container {
    border-bottom: 1px solid $color-grey;
  }
  &__inner {
    background: $color-white;
    position: fixed;
    transition: $gnav-ease-out-transition;
    width: 100%;
    z-index: 9;
    .gnav-block-hidden & {
      transform: translateY(-100%);
      opacity: 0;
    }
    .active-gnav &,
    .search-overlay-displayed & {
      opacity: 1;
      transform: none;
    }
  }
  &__promo-bar {
    transition: $gnav-ease-out-transition;
    height: $gnav-promo-mb-height;
    opacity: 1;
    @media #{$cr19-large-up} {
      height: $gnav-promo-pc-height;
    }
    .gnav-block-sticky-enabled &,
    .active-gnav &,
    .search-overlay-displayed & {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
  &__courtesy-navigation-item-guest {
    @include courtesy-nav-separator;
  }
  &__courtesy-navigation-item-loyalty {
    @include courtesy-nav-separator;
  }
  &__courtesy-navigation-item-non-loyalty {
    @include courtesy-nav-separator;
  }
  &__courtesy-navigation-item,
  &-navigation-courtesy-item {
    display: none;
  }
  &__courtesy-navigation-item-loyalty,
  &-navigation-courtesy-item-loyalty {
    .elc-user-state-loyalty:not(.elc-user-state-anonymous) &,
    .is-loyalty-member & {
      display: block;
    }
  }
  &__courtesy-navigation-item-non-loyalty,
  &-navigation-courtesy-item-non-loyalty {
    .elc-user-state-logged-in:not(.elc-user-state-loyalty):not(.is-loyalty-member) & {
      display: block;
    }
  }
  &__courtesy-navigation-item-guest,
  &-navigation-courtesy-item-guest {
    .elc-user-state-anonymous & {
      display: block;
    }
  }
  &__courtesy-navigation-item-language-toggle,
  &-navigation-courtesy-item-language-toggle {
    @include text-link--style-2;
    display: inline-flex;
    text-decoration: none;
    .gnav-block__courtesy-navigation-item ~ &::before {
      @media #{$cr19-large-up} {
        content: '|';
        clear: both;
        display: inline-block;
        padding: 0 18px;
        color: $color-grey;
        font-size: 0.875rem;
      }
    }
    .gnav-language-toggle {
      &-cta {
        @include text-link--style-2;
        @media #{$cr19-large-up} {
          font-size: 0.875rem;
        }
      }
      .mp_lang {
        &::after {
          position: relative;
          content: '|';
          top: -1px;
          font-size: 0.875rem;
        }
        &:last-child::after {
          content: none;
        }
        .lang-abbr {
          text-transform: uppercase;
        }
        .lang-full {
          display: none;
        }
      }
    }
  }
  &-navigation-courtesy {
    gap: 12px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color-grey;
    padding: 24px 0 40px;
    @media #{$cr19-large-up} {
      display: none;
    }
  }
  &__courtesy {
    &-hamburger-link svg {
      width: $close-icon-size;
      height: $close-icon-size;
    }
    height: $gnav-courtesy-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    @media #{$cr19-large-up} {
      height: $gnav-courtesy-height;
    }
    &-close {
      display: none;
    }
    &-section-links {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
    }
    &-utilities {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 27px;
      .active-gnav &,
      .search-overlay-displayed & {
        visibility: hidden;
        height: 0;
        @media #{$cr19-large-up} {
          visibility: visible;
          height: auto;
        }
      }
    }
    .gnav-block__courtesy-utilities-item {
      height: 24px;
      [role='article'].clearfix {
        overflow: visible;
      }
    }
    &-hamburger {
      @media #{$cr19-large-up} {
        display: none;
      }
    }
    &-search {
      background: url('#{$img_base_path}/icons/src/search-icon.svg') 0 center no-repeat;
      width: 28px;
      .elc-search-icon {
        background: none;
      }
      .elc-search-results-button {
        z-index: 2;
      }
    }
    &-section {
      @media #{$cr19-large-up} {
        display: flex;
        gap: 37px;
        margin-#{$rdirection}: -4px;
      }
    }
    &-navigation-list {
      @media #{$cr19-large-up} {
        display: flex;
      }
    }
    &-navigation-item.text-link--style-2,
    &-navigation-link.text-link--style-2 {
      @media #{$cr19-large-up} {
        font-size: 0.875rem;
      }
    }
  }
  &__courtesy-logo {
    @media #{$cr19-large-up} {
      padding-top: 10px;
    }
    .svg-icon-logo {
      height: 30px;
      width: 110px;
      @media #{$cr19-large-up} {
        height: 40px;
        width: 150px;
      }
    }
  }
  &__main-navigation {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
    &-link.text-link--style-2-bold,
    &-link-back.text-link--style-2-bold {
      font-size: 1.25rem;
      @media #{$cr19-large-up} {
        font-size: 1rem;
      }
      @media #{$cr19-typography-xlarge-up} {
        font-size: 1.125rem;
      }
    }
    &-link-back.button {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 30px;
      svg.svg-icon {
        width: 20px;
        height: 20px;
      }
      @media #{$cr19-large-up} {
        display: none;
      }
    }
    &-link {
      display: flex;
      justify-content: space-between;
      svg.svg-icon {
        width: 20px;
        height: 20px;
        @media #{$cr19-large-up} {
          display: none;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 5px;
      @media #{$cr19-large-up} {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
        padding: 0;
        height: $gnav-pc-nav-height;
        align-items: center;
      }
    }

    &-layout {
      visibility: hidden;
      position: fixed;
      background-color: $color-white;
      z-index: 9;
      width: 100%;
      #{$ldirection}: 0;
      overflow-y: auto;
      opacity: 0;

      @media #{$cr19-large-up} {
        position: absolute;
        top: $gnav-pc-full-height;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 1;
      }
      &-content {
        @media #{$cr19-large-up} {
          opacity: 0;
        }
      }

      &-content-close {
        display: none;
        &-label {
          @media #{$cr19-large-up} {
            margin-top: -24px;
          }
        }
      }
      [role='article'].clearfix {
        overflow: visible;
      }
    }
    &-link-back {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 30px;
      margin-#{$ldirection}: -5px;
      @media #{$cr19-large-up} {
        display: none;
      }
      span {
        padding-top: 5px;
      }
    }
  }
  &__navigation-item {
    &:empty {
      display: none;
    }
    @media #{$cr19-large-up} {
      height: calc(#{$gnav-pc-nav-height} + 1px);
      display: flex;
      align-items: center;
      padding: 0 10px;
      flex-grow: 1;
    }
    &:first-child {
      @media #{$cr19-large-up} {
        padding-#{$ldirection}: 0;
      }
    }
    &:last-child {
      @media #{$cr19-large-up} {
        padding-#{$rdirection}: 0;
        flex-grow: 0;
      }
    }
    &:hover {
      .gnav-block__main-navigation-link {
        @media #{$cr19-large-up} {
          text-decoration: underline;
          text-underline-offset: $default-underline-offset;
        }
      }
      .gnav-block__main-navigation-layout {
        @media #{$cr19-large-up} {
          visibility: visible;
          opacity: 1;
          height: auto;
          padding-top: 30px;
          padding-bottom: 30px;
        }
        &-content {
          @media #{$cr19-large-up} {
            opacity: 1;
            animation: fade 0.3s ease-in;
          }
        }

        .gnav-block-sticky-enabled & {
          @media #{$cr19-large-up} {
            position: fixed;
            width: 100%;
            top: $gnav-pc-sticky-height;
          }
        }
      }
    }
    label.gnav-block__main-navigation-link {
      @media #{$cr19-large-up} {
        pointer-events: none;
      }
    }
  }
  &__input-navigation:checked {
    & ~ .gnav-block__main-navigation-layout {
      visibility: visible;
      padding-bottom: 100px;
      top: $gnav-courtesy-height;
      height: 100%;
      opacity: 1;

      @media #{$cr19-large-up} {
        height: auto;
        top: $gnav-pc-full-height;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .gnav-block__main-navigation-layout-content-close {
        @media #{$cr19-large-up} {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 15px 0;
        }
      }
      .gnav-block-sticky-enabled & {
        @media #{$cr19-large-up} {
          top: $gnav-pc-sticky-height;
        }
      }
      .gnav-block__main-navigation-layout-content {
        @media #{$cr19-large-up} {
          opacity: 1;
          animation: fade 0.3s ease-in;
        }
      }
    }
    & ~ .gnav-block__main-navigation-link {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
    }
  }
  #gnav_mobile_input_hamburger_trigger:checked {
    & ~ .gnav-block__nav-container {
      .gnav-block__main-navigation {
        position: fixed;
        width: 100%;
        height: calc(100% - #{$gnav-courtesy-height});
        background-color: $color-white;
        z-index: 999;
        #{$ldirection}: 0;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: $gnav-animation;
        @media #{$cr19-medium-up} {
          padding: 0 44px;
        }
        @media #{$cr19-large-up} {
          position: absolute;
        }
      }

      .gnav-block__courtesy {
        z-index: 9999;
        width: 100%;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        height: $gnav-courtesy-height;
        animation: $gnav-animation;

        .gnav-block__courtesy-utilities-item {
          display: none;
        }
        .gnav-block__courtesy-close {
          display: block;
          .svg-icon {
            width: $close-icon-size;
            height: $close-icon-size;
          }
        }
      }
    }
    & ~ .gnav-block__promo-bar {
      display: none;
    }
  }
  input[tabindex='-1'] {
    position: absolute;
    #{$ldirection}: -9999px;
  }
  .svg-icon,
  .svgicon--bag {
    width: 24px;
    height: 24px;
    fill: $color-black;
  }
  .svg-icon-arrow {
    transform: rotate(180deg);
  }
  &__dark-overlay {
    @media #{$cr19-large-up} {
      background-color: $cr19-bg-dark-transparent;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      width: 100%;
      #{$ldirection}: 0;
      top: 0;
      height: 100%;
      transition: visibility 0.7s, opacity 0.7s;
    }
    .active-gnav & {
      @media #{$cr19-large-up} {
        visibility: visible;
        opacity: 1;
        z-index: 9;
      }
    }
  }
}
// Search styles.
.sd-search-gnav-input-field {
  .elc-shade-swatches-wrapper {
    display: none;
  }
  .elc-search-suggestion-value {
    @media #{$cr19-large-up} {
      border: none;
    }
  }
  .elc-search-suggestion-wrapper {
    @media #{$cr19-large-up} {
      padding-top: 0;
    }
  }
  .elc-search-typeahead-title {
    @media #{$cr19-large-up} {
      padding-bottom: 0;
    }
  }
  .elc-search-results-wrapper {
    padding: 20px;
  }
  .elc-search-close-icon {
    width: $close-icon-size;
    height: $close-icon-size;
    @media #{$cr19-large-up} {
      width: 20px;
      height: 20px;
    }
  }
  .elc-grid-item-product {
    padding: 0;
    @media #{$cr19-large-up} {
      padding: 8px;
    }
  }
  .elc-product-grid-wrapper {
    margin: 20px 0;
  }
  .elc-search-trending-term__link,
  .elc-search-typeahead-value {
    @include text-link--style-7;
  }
  .elc-search-active-screen-wrapper {
    width: 100%;
    top: $gnav-courtesy-height;
    height: calc(100% - #{$gnav-courtesy-height});
    visibility: visible;
    @media #{$cr19-large-up} {
      top: $gnav-pc-sticky-height;
      height: calc(100% - #{$gnav-pc-sticky-height});
    }
    .active-gnav & {
      z-index: -1;
    }
  }
  .elc-search-gnav-box {
    @media #{$cr19-large-up} {
      padding: 60px 40px 12px;
    }
    .elc-input-search-field {
      font-weight: 700;
      @include text-body-text--large;
      &::placeholder {
        @include text-body-text--large;
      }
    }
  }
  .elc-search-close-icon-wrapper {
    position: fixed;
    #{$rdirection}: 20px;
    top: 20px;
    @media #{$cr19-large-up} {
      position: absolute;
      #{$rdirection}: 45px;
      top: 20px;
    }
  }
  input.elc-input-search-field {
    @include text-body-text--large;
    &::placeholder {
      @include text-body-text--large;
    }
  }
  .elc-voice-search-wrapper {
    #{$rdirection}: 5px;
    top: 5px;
  }
  .elc-search-enhanced-trending-title,
  .elc-search-enhanced-grid-title,
  .elc-search-result-overlay-message {
    @include text-body-text--large($font-family: $helvetica-bold-font-family);
  }
}
// Cart styles.
.header-gnav-cart {
  .cart-confirm-wrapper__bottom-section {
    @media #{$cr19-large-up} {
      padding-bottom: 60px;
    }
  }
  .gnav-header-formatter__close {
    position: fixed;
    #{$rdirection}: 20px;
    z-index: 999999;
    margin-top: -40px;
    @media #{$cr19-large-up} {
      #{$rdirection}: 0;
      padding: 20px;
      top: 10px;
      margin-top: 0;
    }
  }
  .gnav-header-formatter__close-icon {
    width: $close-icon-size;
    height: $close-icon-size;
  }
  .header-gnav-cart__trigger {
    display: inline-block;
  }
  .gnav-header-formatter__sections-panel {
    background: $color-white;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: $gnav-courtesy-height;
    width: 100%;
    height: calc(100% - #{$gnav-courtesy-height});
    z-index: 99;
    #{$rdirection}: 0;
    transform: translateX(100%);
    transition: $gnav-ease-out-transition;
    visibility: hidden;
    @media #{$cr19-large-up} {
      top: 0;
      height: 100%;
      width: 534px;
    }
    .active-gnav & {
      position: fixed;
      display: flex;
      flex-direction: column;
      visibility: visible;
      transform: translateX(0);
      @media #{$cr19-large-up} {
        width: 534px;
      }
    }
    .header-gnav-cart__content-wrapper {
      overflow-y: auto;
    }
  }
  .cart-confirm-wrapper__top-section {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
  }
}

.header-gnav__wrapper {
  .vto-active:not(.active-gnav) & {
    display: none;
    @media #{$cr19-medium-up} {
      display: block;
    }
  }
  [role='article'].clearfix:first-child {
    overflow: visible;
    @media #{$cr19-large-up} {
      overflow: hidden;
    }
  }
}

// Override on body for shades sidebar when active
body {
  &.product-shades-sidebar-active {
    overflow: hidden;
  }
  // Fixes cart overlay when shades slide-out is active.
  &.active-gnav {
    .gnav-block {
      z-index: 99999;
      position: fixed;
    }
  }
}
